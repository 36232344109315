<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">AKTIVITAS HARIAN JEJARING</p>
          <router-link
            v-if="isPSPD"
            :to="{ name: 'kegiatan-pspd-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
          <!-- <a v-if="kegiatanLength > 0" class="card-header-icon" @click.stop.prevent="saveObjects()">
            <b-icon icon="save" size="is-small" class="has-text-light" />
          </a> -->
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <div class="no-padding filter2">
              <b-select v-model="filterMap.jenis" placeholder="Jenis">
                <option v-if="filterMap.jenis" class="nofilter" value>Jenis</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </b-select>
            </div>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="kegiatanList"
            :mobile-cards="false"
          >
            <b-table-column label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column label="Aktivitas" v-slot="props">
              <span class="tanggal">{{ props.row.tanggal }}</span>
              <br />
              <router-link :to="{ name: 'kegiatan-pspd-detail', params: { id: props.row.id } }">
                <!-- <b-icon class="small-icon" size="is-small" icon="video" /> -->
                {{ props.row.kegiatan }}
              </router-link>
              <br />
              <small>{{ props.row.metode }} [{{ props.row.jenis }}]</small>
              <br />
              <span v-if="!isPSPD">
                {{ props.row.pspd }}
                <br />
              </span>
              <small>{{ props.row.sub_departemen }} - {{ props.row.preceptor }}</small>
            </b-table-column>
            <!-- <template v-if="!isPSPD">
              <b-table-column field="nilai_sikap" label="Nilai Sikap" v-slot="props">
                <b-field v-if="!includes(props.row.id, 'nilai_sikap')" :type="getType(props.index)">
                  <b-numberinput
                    class="nilai"
                    min="0"
                    max="100"
                    @input="validateInline(props.index)"
                    v-model="props.row.nilai_sikap"
                    :controls="false"
                  ></b-numberinput>
                </b-field>
                <span v-else-if="props.row.nilai_sikap == null" class="icon has-text-info">
                  <b-icon size="is-small" icon="minus" />
                </span>
                <template v-else>{{ props.row.nilai_sikap }}</template>
              </b-table-column>
            </template>
            <b-table-column field="verifikasi" label="Verifikasi" v-slot="props">
              <template v-if="includes(props.row.id, 'verifikasi')">
                <b-select v-model="props.row.verifikasi">
                  <option value="Belum">Belum</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </b-select>
              </template>
              <hasil-mark v-else :hasil="props.row.verifikasi"></hasil-mark>
            </b-table-column> -->

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { has } from "lodash";
import { mapGetters } from "vuex";
// import { mapState } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
// import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import KegiatanPSPDList from "../models/kegiatanJejaringList.js";

export default {
  name: "KegiatanPSPDList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    // HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  data() {
    this.kegiatanListMdl = new KegiatanPSPDList();
    this.listMdl = this.kegiatanListMdl; // alias untuk digunakan di paginationmixin
    let obv = this.kegiatanListMdl.getObservables();
    return obv;
  },
  computed: {
    // ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPSPD"]),
    // kegiatanLength() {
    //   return this.kegiatanList.length > 0;
    // },
  },
  // methods: {
  //   includes(id, type) {
  //     if (has(this.kegiatanBelumSet, id)) {
  //       if (type == "verifikasi")
  //         return this.kegiatanBelumSet[id][type] == "Belum";
  //       return this.kegiatanBelumSet[id][type];
  //     }
  //     return null;
  //   },
  //   needConfirmation() {
  //     let kegiatanBelumSet = this.kegiatanList.filter(
  //       (d) =>
  //         this.kegiatanBelumSet[d.id].verifikasi != d.verifikasi ||
  //         this.kegiatanBelumSet[d.id].nilai_sikap != d.nilai_sikap
  //     );
  //     return kegiatanBelumSet.length > 0;
  //   },
  //   filter() {
  //     this.kegiatanListMdl.applyFilter();
  //   },
  //   applyFilter() {
  //     if (this.needConfirmation()) {
  //       this.showConfirmationDlg(() => {
  //         this.saveObjects(this.filter);
  //       });
  //     } else {
  //       this.filter();
  //     }
  //   },
  //   getType(idx) {
  //     if (has(this.errorList[idx], "nilai_sikap")) {
  //       return this.errorList[idx]["nilai_sikap"] ? "is-danger" : "";
  //     }
  //     return "";
  //   },
  //   validateInline(idx) {
  //     this.kegiatanListMdl.validateInline(idx);
  //   },
  // },
  mixins: [onlineCheckMixin, paginationMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.tanggal {
  font-size: 0.8rem !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.small-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}

::v-deep option.nofilter {
  color: #999;
}
</style>
